import {
  bulkChangeUserGroups,
  bulkChangeUserRoles,
  bulkChangeUserLoginType,
  bulkChangeUserStatus,
  bulkDeleteAccounts,
  bulkChangeUserType,
  createUser,
  deleteUser,
  updateUser,
  triggerResetPassword
} from '@/api/user';

import { t } from '@/i18next';
import { alphabeticalSorting } from '@/lib/sorting';

import {
  BULK_CHANGE_USER_PRODUCT_ACCESS_MODAL,
  BULK_CHANGE_USER_GROUPS_MODAL,
  BULK_CHANGE_USER_LOGIN_TYPE_MODAL,
  BULK_CHANGE_USER_STATUS_MODAL,
  BULK_DELETE_ACCOUNTS_MODAL,
  CREATE_EDIT_ACCOUNT_MODAL,
  DELETE_ENTITY_MODAL,
  SELECT_PRODUCT_MODAL,
  VIEW_ACCOUNT_MODAL,
  TRIGGER_RESET_PASSWORD_MODAL,
  USER_HISTORY_ACTION_DELETE
} from '@/constants/modals';

import {
  IDEA_DATABASE_SUPERVISOR,
  IDEA_DATABASE_USER_ADMIN,
  PATENTS_ADMIN,
  PATENTS_EXTERNAL,
  PATENTS_FULL_USER,
  PATENTS_LIMITED_USER,
  PATENTS_REVIEWER,
  PATENTS_ROLES,
  ROLE_ANALYSIS,
  ROLE_MENUS_TAGS_RANKINGS_MANAGEMENT,
  ROLE_REPORTS
} from '@/constants/user';

import {
  PRODUCT_IDEA_DATABASE,
  PRODUCT_PATENT_PLATFORM
} from '@/constants/contentType';

import { USERS_API } from '@/constants/api';

import type {
  BulkChangeUserGroupsParams,
  BulkChangeUserLoginTypeParams,
  BulkChangeUserRolesParams,
  BulkChangeUserStatusParams,
  BulkChangeUserTypeParams,
  BulkDeleteAccountsParams,
  CreateUserParams,
  UpdateUserParams
} from '@/api/user/types';

import type {
  CurrentUser,
  IdeaDatabaseUserRole,
  ListUser,
  PatentsPlatformUserRole,
  PatentsPlatformUserType,
  UserHistory
} from '@/types/users';

import type { ParseKeys, TFunction } from 'i18next';
import type { SelectProductParams, UseModal } from '@/types/modals';

import type { PromiseResolve } from '@/types/helpers';
import type { Ref } from 'vue';

import type {
  CreateOrganizationUserPresetParams,
  UpdateOrganizationUserPresetParams
} from '@/api/organizationUserPreset/types';

export const parseUserParams = (
  userParams:
    | CreateUserParams
    | CreateOrganizationUserPresetParams
    | UpdateUserParams
    | UpdateOrganizationUserPresetParams
) => {
  const filteredParams = { ...userParams };

  if (
    filteredParams.idea_database_roles &&
    filteredParams.idea_database_roles.length > 0
  ) {
    filteredParams.idea_database_roles = filteredParams.idea_database_roles.map(
      role => (role === IDEA_DATABASE_USER_ADMIN ? 1 : role)
    );
  }

  const keysToDelete: (
    | keyof UpdateUserParams
    | keyof UpdateOrganizationUserPresetParams
  )[] = ['presetId'];

  if ('id' in userParams) {
    keysToDelete.push('id');
  }
  if (!userParams.groups?.length) {
    keysToDelete.push('groups');
  }
  if (!userParams.patents_product_enabled) {
    keysToDelete.push(
      ...(['patents_user_type', 'patents_selected_roles'] as const)
    );
  }
  if (!userParams.idea_database_product_enabled) {
    keysToDelete.push('idea_database_roles');
  }
  if (!userParams.note) {
    keysToDelete.push('note');
  }

  keysToDelete
    .filter(key => key in filteredParams)
    .forEach(
      key =>
        delete (
          filteredParams as UpdateUserParams &
            UpdateOrganizationUserPresetParams
        )[key]
    );

  return filteredParams;
};

export const prepareHistoryActions = (
  historyEntry: UserHistory,
  t: TFunction
) => {
  const isProductAction = historyEntry.action !== USER_HISTORY_ACTION_DELETE;

  const getProductName = () => {
    if (!historyEntry.productId) {
      return null;
    }

    return historyEntry.productId === 1
      ? t('organization.patents_platform')
      : t('organizationDashboard.idea_database');
  };

  const productName = getProductName() ? `(${getProductName()})` : '';

  return `${t(historyEntry.action as ParseKeys)} ${isProductAction ? productName : ''}`;
};

export function filterPatentPlatformRoles(
  roles: PatentsPlatformUserRole[],
  currentUser: Ref<CurrentUser>
) {
  return roles
    .filter(role => {
      if (
        !currentUser.value.organization.isReportModuleActive &&
        !currentUser.value.organization.isAnalysisModuleActive
      ) {
        return role !== ROLE_REPORTS && role !== ROLE_ANALYSIS;
      }
      if (!currentUser.value.organization.isReportModuleActive) {
        return role !== ROLE_REPORTS;
      }
      if (!currentUser.value.organization.isAnalysisModuleActive) {
        return role !== ROLE_ANALYSIS;
      }
      return role;
    })
    .sort((a, b) => a - b);
}

export function filterIdeaDatabaseRoles(
  roles: IdeaDatabaseUserRole[],
  currentUser: Ref<CurrentUser>
) {
  return alphabeticalSorting({
    array: roles.filter(role => {
      if (!currentUser.value.organization.isSupervisorAvailable) {
        return role !== IDEA_DATABASE_SUPERVISOR;
      }

      return role;
    }),
    param: false
  });
}

export function setPatentsPlatformRoles(userType: PatentsPlatformUserType) {
  const roles = [...PATENTS_ROLES[userType as keyof typeof PATENTS_ROLES]];

  const rolesToRemove: Record<PatentsPlatformUserType, number | undefined> = {
    [PATENTS_ADMIN]: ROLE_REPORTS,
    [PATENTS_FULL_USER]: ROLE_MENUS_TAGS_RANKINGS_MANAGEMENT,
    [PATENTS_LIMITED_USER]: undefined,
    [PATENTS_REVIEWER]: undefined,
    [PATENTS_EXTERNAL]: undefined
  };

  const roleToRemove = rolesToRemove[userType];

  return roleToRemove ? roles.filter(role => role !== roleToRemove) : roles;
}

export function openAccountCreateModal(openModal: UseModal['openModal']) {
  async function onCreateAccountHandler(
    resolve: PromiseResolve,
    userParams: CreateUserParams
  ) {
    const data = await createUser(
      parseUserParams(userParams) as CreateUserParams
    );
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_ACCOUNT_MODAL>(CREATE_EDIT_ACCOUNT_MODAL, {
    confirmAction: onCreateAccountHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    successMessage: 'common.createdSuccessfully',
    allowGoBack: true
  });
}

export function openAccountDeleteModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function onDeleteAccountHandler(resolve: PromiseResolve) {
    const data = await deleteUser(user.id);
    resolve(data);
  }
  const entityName = `${user.fullName} (${user.email})`;

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteAccountHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    name: entityName,
    deselectItems: user.id,
    confirmTranslation: 'common.delete',
    modalMessage: 'manageAccounts.delete_user',
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('manageAccounts.user'),
      entityName: entityName
    }
  });
}

export function openAccountEditModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function onEditAccountHandler(
    resolve: PromiseResolve,
    userParams: UpdateUserParams
  ) {
    const data = await updateUser(
      userParams.id as number,
      parseUserParams(userParams) as UpdateUserParams
    );
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_ACCOUNT_MODAL>(CREATE_EDIT_ACCOUNT_MODAL, {
    confirmAction: onEditAccountHandler,
    useStatusConfirmation: true,
    allowGoBack: true,
    user,
    invalidateQueryKey: USERS_API,
    successMessage: 'common.updatedSuccessfully'
  });
}

export function openViewAccountModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function onEditAccountHandler(resolve: PromiseResolve) {
    openAccountEditModal(openModal, user);

    resolve(false);
  }
  openModal<typeof VIEW_ACCOUNT_MODAL>(VIEW_ACCOUNT_MODAL, {
    confirmAction: onEditAccountHandler,
    invalidateQueryKey: USERS_API,
    useStatusConfirmation: false,
    user
  });
}

export async function openBulkChangeUserStatusModal(
  openModal: UseModal['openModal'],
  active: boolean
) {
  async function bulkChangeStatusHandler(
    resolve: PromiseResolve,
    bulkChangeParams: BulkChangeUserStatusParams
  ) {
    const data = await bulkChangeUserStatus(bulkChangeParams);
    resolve(data);
  }

  openModal<typeof BULK_CHANGE_USER_STATUS_MODAL>(
    BULK_CHANGE_USER_STATUS_MODAL,
    {
      confirmAction: bulkChangeStatusHandler,
      active,
      useStatusConfirmation: true,
      isBulkAction: true,
      invalidateQueryKey: USERS_API,
      successMessage: 'manageAccounts.bulk_change_status_started'
    }
  );
}

export function openBulkChangeUserGroupsModal(
  openModal: UseModal['openModal']
) {
  async function bulkChangeUserGroupsHandler(
    resolve: PromiseResolve,
    bulkChangeParams: BulkChangeUserGroupsParams
  ) {
    const data = await bulkChangeUserGroups(bulkChangeParams);
    resolve(data);
  }

  openModal<typeof BULK_CHANGE_USER_GROUPS_MODAL>(
    BULK_CHANGE_USER_GROUPS_MODAL,
    {
      confirmAction: bulkChangeUserGroupsHandler,
      useStatusConfirmation: true,
      isBulkAction: true,
      invalidateQueryKey: USERS_API,
      successMessage: 'manageAccounts.bulk_change_user_groups_started'
    }
  );
}

export function openBulkDeleteAccountsModal(openModal: UseModal['openModal']) {
  async function bulkDeleteAccountsHandler(
    resolve: PromiseResolve,
    bulkDeleteParams: BulkDeleteAccountsParams
  ) {
    const data = await bulkDeleteAccounts(bulkDeleteParams);
    resolve(data);
  }

  openModal<typeof BULK_DELETE_ACCOUNTS_MODAL>(BULK_DELETE_ACCOUNTS_MODAL, {
    confirmAction: bulkDeleteAccountsHandler,
    useStatusConfirmation: true,
    isBulkAction: true,
    deselectItems: true,
    invalidateQueryKey: USERS_API,
    successMessage: 'manageAccounts.bulk_delete_accounts_started'
  });
}

export function openProductSelectModal(openModal: UseModal['openModal']) {
  async function onProductSelectHandler(
    resolve: PromiseResolve,
    selectProductParams: SelectProductParams
  ) {
    openBulkChangeProductAccessModal(openModal, {
      isPatentsPlatformAvailable:
        selectProductParams.selectedProduct === PRODUCT_PATENT_PLATFORM,
      isIdeaDatabaseAvailable:
        selectProductParams.selectedProduct === PRODUCT_IDEA_DATABASE
    } as CurrentUser);
    resolve(false);
  }

  openModal<typeof SELECT_PRODUCT_MODAL>(SELECT_PRODUCT_MODAL, {
    confirmAction: onProductSelectHandler,
    useStatusConfirmation: false
  });
}

export function openBulkChangeProductAccessModal(
  openModal: UseModal['openModal'],
  currentUser: CurrentUser
) {
  if (
    currentUser.isPatentsPlatformAvailable &&
    currentUser.isIdeaDatabaseAvailable
  ) {
    openProductSelectModal(openModal);
    return;
  }

  async function onBulkChangeProductAccessHandler(
    resolve: PromiseResolve,
    bulkChangeProductParams:
      | BulkChangeUserRolesParams
      | BulkChangeUserTypeParams
  ) {
    const data = await (currentUser.isPatentsPlatformAvailable
      ? bulkChangeUserType(bulkChangeProductParams as BulkChangeUserTypeParams)
      : bulkChangeUserRoles(
          bulkChangeProductParams as BulkChangeUserRolesParams
        ));
    resolve(data);
  }

  openModal<typeof BULK_CHANGE_USER_PRODUCT_ACCESS_MODAL>(
    BULK_CHANGE_USER_PRODUCT_ACCESS_MODAL,
    {
      confirmAction: onBulkChangeProductAccessHandler,
      useStatusConfirmation: true,
      isBulkAction: true,
      invalidateQueryKey: USERS_API,
      successMessage: currentUser.isPatentsPlatformAvailable
        ? 'userOrganization.bulk_organization_user_types_change_started'
        : 'userOrganization.bulk_organization_user_roles_change_started',
      selectedProduct: currentUser.isPatentsPlatformAvailable
        ? PRODUCT_PATENT_PLATFORM
        : PRODUCT_IDEA_DATABASE
    }
  );
}

export function openTriggerResetPasswordModal(
  openModal: UseModal['openModal'],
  user: ListUser
) {
  async function onTriggerPasswordHandler(resolve: PromiseResolve) {
    const data = await triggerResetPassword(user.id);
    resolve(data);
  }
  const entityName = `${user.fullName} (${user.email})`;

  openModal<typeof TRIGGER_RESET_PASSWORD_MODAL>(TRIGGER_RESET_PASSWORD_MODAL, {
    confirmAction: onTriggerPasswordHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: USERS_API,
    entityName,
    email: user.email,
    successMessage: 'manageAccounts.trigger_password_success'
  });
}

export function openBulkChangeUserLoginTypeModal(
  openModal: UseModal['openModal']
) {
  async function bulkChangeUserLoginTypeHandler(
    resolve: PromiseResolve,
    bulkChangeParams: BulkChangeUserLoginTypeParams
  ) {
    const data = await bulkChangeUserLoginType(bulkChangeParams);
    resolve(data);
  }

  openModal<typeof BULK_CHANGE_USER_LOGIN_TYPE_MODAL>(
    BULK_CHANGE_USER_LOGIN_TYPE_MODAL,
    {
      confirmAction: bulkChangeUserLoginTypeHandler,
      useStatusConfirmation: true,
      isBulkAction: true,
      invalidateQueryKey: USERS_API,
      successMessage: 'manageAccounts.bulk_change_user_login_started'
    }
  );
}
